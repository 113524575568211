<template>
  <div class="dashcontainer">
    <Header></Header>
    <div class="maincontainer">
      <div class="basenav">
        <button @click="goto('Dashboard2')" class="nextBtn">
          <img
            src="@/assets/images/arrowBack.svg"
            alt=""
            width="24"
            height="24"
          />
        </button>
        <span>Branch</span>
      </div>
      <div class="bodycontainer">
        <div class="headertitle">
          <div class="header d-flex align-items-center">
            <img :src="getImage('dashboard', 'branch')" alt="" />{{
              'Branch/Terminal Givepoint'
            }}
          </div>
          <div class="filterinput d-flex align-items-center">
            <a-range-picker
              v-model="date"
              :showToday="false"
              :ranges="ranges"
              @change="onChange"
            >
              <div class="inputdate">
                {{ date ? dateFormat() : 'Select Date' }}
                <img
                  class="calendar"
                  :src="getImage('dashboard', 'calendar')"
                  alt=""
                />
              </div>
            </a-range-picker>
            <a-button class="export ml-3" @click="exportFile">
              <img :src="getImage('dashboard', 'export')" alt="" />Export Excel
              File
            </a-button>
          </div>
        </div>
        <div class="filter mt-3">
          <a-dropdown
            :disables="data && data.length <= 1"
            placement="bottomRight"
          >
            <button class="btn-sort">
              {{ lbl['user-permissions-sortby'] }}
              <img :src="getImage('dashboard', 'sort')" alt="Sort icon" />
            </button>
            <a-menu v-model="sort" slot="overlay" @click="handleSort">
              <a-sub-menu key="point" :title="lbl['dashboard-give-points']">
                <a-menu-item key="most">{{ lbl['most-least'] }}</a-menu-item>
                <a-menu-item key="less">{{ lbl['least-most'] }}</a-menu-item>
              </a-sub-menu>
              <a-sub-menu key="redeemed" :title="lbl['smart-target-redeemed']">
                <a-menu-item key="most">{{ lbl['most-least'] }}</a-menu-item>
                <a-menu-item key="less">{{ lbl['least-most'] }}</a-menu-item>
              </a-sub-menu>
              <a-sub-menu
                key="active_member"
                :title="lbl['dashboard-active-member']"
              >
                <a-menu-item key="most">{{ lbl['most-least'] }}</a-menu-item>
                <a-menu-item key="less">{{ lbl['least-most'] }}</a-menu-item>
              </a-sub-menu>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="rankbody">
          <a-empty v-if="!branchList.length" class="mt-5" />
          <RankItem
            v-for="rank in branchList"
            :key="rank.rank"
            :data="rank"
            :customclass="customclass"
            @click.native="onOpen(rank)"
          />
        </div>
        <div class="d-flex justify-content-end mt-4">
          <a-pagination
            v-model="currentPage"
            :default-current="1"
            :total="data.length"
            @change="onChangePage"
            hideOnSinglePage
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import moment from 'moment'
import Utility from '../../helper/Utility'
import BzbsDashboard from '../../core/Dashboard/BzbsDashboard'
import RankItem from './components/RankItem'
import XLSX from 'xlsx'

export default {
  components: {
    Header,
    RankItem,
  },
  mixins: [Mixin],
  data() {
    return {
      onopen: false,
      currentPage: 1,
      ranges: Utility.ranges,
      date: [moment().subtract(30, 'days'), moment()],
      data: [],
      branchRank: [],
      customclass: 'flex',
    }
  },
  computed: {
    branchList() {
      return this.data.slice((this.currentPage - 1) * 10, this.currentPage * 10)
    },
  },
  mounted() {
    let query = this.$route.query.data
    this.date = [moment.unix(query[0]), moment.unix(query[1])]
    this.getBranchRank(this.date)
  },
  methods: {
    onChangePage(page) {
      this.currentPage = page
    },
    onOpen(data) {
      data.open = !data.open
    },
    onChange() {
      this.getBranchRank(this.date)
    },
    handleSort(e) {
      this.currentPage = 1
      console.log(this.data)
      switch (e.keyPath[1]) {
        case 'point':
          switch (e.key) {
            case 'most':
              this.data = this.data.sort(
                (a, b) => b.adon[0].value - a.adon[0].value,
              )
              break
            case 'less':
              this.data = this.data.sort(
                (a, b) => a.adon[0].value - b.adon[0].value,
              )
              break
          }
          break
        case 'redeemed':
          switch (e.key) {
            case 'most':
              this.data = this.data.sort(
                (a, b) => b.adon[1].value - a.adon[1].value,
              )
              break
            case 'less':
              this.data = this.data.sort(
                (a, b) => a.adon[1].value - b.adon[1].value,
              )
              break
          }
          break
        case 'active_member':
          switch (e.key) {
            case 'most':
              this.data = this.data.sort(
                (a, b) => b.adon[2].value - a.adon[2].value,
              )
              break
            case 'less':
              this.data = this.data.sort(
                (a, b) => a.adon[2].value - b.adon[2].value,
              )
              break
          }
          break
      }
      this.data.forEach((item, i) => {
        item.rank = i + 1
      })
      console.log(this.data)
    },
    exportFile() {
      let data = this.branchRank.map(item => {
        return {
          'Branch Name': item.BranchName,
          'Branch Id': item.BranchId,
          'Give point': item.GivePoint,
          Redeemed: item.Redeemed,
          'Active members': item.ActiveMember,
        }
      })
      const dataWS = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(
        wb,
        'Campaign_Branch_Rank_' + Date.parse(new Date()) + '.xlsx',
      )
    },
    getBranchRank(date = [moment().subtract(30, 'days'), moment()]) {
      BzbsDashboard.getBranchRank({
        startdate: date[0].format('YYYY-MM-DD'),
        enddate: date[1].format('YYYY-MM-DD'),
      })
        .then(res => {
          this.branchRank = res.data.data
          this.data = []
          this.branchRank.forEach((item, index) => {
            this.data.push({
              rank: index + 1,
              img: '',
              badge: [],
              open: false,
              text: item.BranchName,
              adon: [
                {
                  key: 'GivePoint',
                  text: this.lbl['dashboard-give-points'],
                  value: item.GivePoint ? item.GivePoint : 0,
                },
                {
                  key: 'Redeemed',
                  text: this.lbl['smart-target-redeemed'],
                  value: item.Redeemed ? item.Redeemed : 0,
                  subfix: this.lbl['dashboard-suffix-times'],
                },
                {
                  key: 'ActiveMember',
                  text: this.lbl['dashboard-active-member'],
                  value: item.ActiveMember ? item.ActiveMember : 0,
                  subfix: this.lbl['permission-type-member'],
                },
              ],
              details: {
                coin: {
                  icon: 'coin',
                  title: this.lbl['dashboard-give-points'],
                  value: item.GivePoint ? item.GivePoint : 0,
                  class: 'flex-max',
                },
                ticket: {
                  icon: 'ticket',
                  title: this.lbl['smart-target-redeemed'],
                  value: item.Redeemed ? item.Redeemed : 0,
                  et: 'Times',
                  class: 'flex-max',
                },
                fire: {
                  icon: 'fire',
                  title: this.lbl['dashboard-active-member'],
                  value: item.ActiveMember ? item.ActiveMember : 0,
                  et: this.lbl['permission-type-member'],
                  class: 'flex-max',
                },
              },
            })
          })
          this.$forceUpdate()
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.dashcontainer {
  min-height: 100vh;

  .basenav {
    display: flex;
    padding: 18px 16px;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    background: var(--surface-primary, #fff);
    box-shadow: 0px -1px 0px 0px #f0f0f0 inset;

    button {
      padding: 5px;
      border-radius: 8px;
      border: 1px solid var(--grey-grey-darken-1, #757575);
      background: var(--surface-primary, #fff);
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
        0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    }

    span {
      color: var(--black, #192a3e);
      font-size: 24px;
      font-weight: 400;
      text-transform: capitalize;
    }
  }

  .maincontainer {
    .bodycontainer {
      margin-top: 16px;
      padding: 16px;
      gap: 16px;
      border-radius: 16px;
      background: var(--surface-primary, #fff);

      .headertitle {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header {
          color: var(--text-light-text-title, var(--text-text-title, #4a4a4a));
          font-size: 16px;
          font-weight: 700;
          line-height: 150%;

          img {
            margin-right: 8px;
            border-radius: 8px;
            padding: 8px;
            background: var(--primary-gold-bzb-gold-9, #fff6e6);
          }
        }
        .export {
          display: flex;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 6px;
          border: 1px solid var(--polar-green-7, #389e0d);
          background: var(--brand-color-white, #fff);

          color: var(--polar-green-7, #389e0d);
          font-size: 16px;
          font-weight: 700;
          line-height: 150%;
        }
      }
      .filter {
        display: flex;
        justify-content: flex-end;
        border: unset;

        .btn-sort {
          display: flex;
          padding: 8px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 8px;
          border: 1px solid #e0e0e0;
          background-color: #ffffff;
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import '@/style/base/common.scss';
.ant-pagination-item-active,
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: $primary-hover !important;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  font-size: 10px;
  &:hover {
    color: $primary-hover !important;
    border-color: $primary-hover !important;
  }
}
.ant-calendar-footer-extra {
  .ant-tag {
    color: #fa8c16 !important;
    background: #fff7e6 !important;
    border-color: #ffd591 !important;
  }
}

.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  background: #fa8c16 !important;
  color: #fff !important;
}

.ant-calendar-range .ant-calendar-in-range-cell::before {
  background: #fff7e6 !important;
}

.ant-calendar-today .ant-calendar-date {
  color: #fa8c16 !important;
  border-color: #fa8c16 !important;
}

.ant-calendar-date:hover {
  background: #fff7e6 !important;
}

.ant-calendar-header a:hover {
  color: #fa8c16 !important;
}

.inputdate {
  display: flex;
  position: relative;
  min-width: 200px;
  height: 40px;
  padding: 5px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 6px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-1, #fff);
  cursor: pointer;

  .calendar {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}

.dashcontainer .anticon {
  vertical-align: 0;
}
</style>
